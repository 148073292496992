import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import { graphql, Link } from 'gatsby'
import FeaturesGrid from 'components/features-grid'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/chain-saws/homeowner-saws/',
    imageId: 'homeownerSawsImage',
    name: 'Homeowner Chainsaws',
    alt: 'STIHL Homeowner Saws',
  },
  {
    link: '/stihl/chain-saws/farm-and-ranch-saws/',
    imageId: 'farmAndRanchSawsImage',
    name: 'Farm & Ranch Chainsaws',
    alt: 'STIHL Farm and Ranch Chainsaws',
  },
  {
    link: '/stihl/chain-saws/in-tree-saws/',
    imageId: 'inTreeSawsImage',
    name: 'In Tree Chainsaws',
    alt: 'STIHL In Tree Chainsaws',
  },
  {
    link: '/stihl/chain-saws/professional-saws/',
    imageId: 'professionalSawsImage',
    name: 'Professional Chainsaws',
    alt: 'STIHL Professional Chainsaws',
  },
  {
    link: '/stihl/chain-saws/battery-saws/',
    imageId: 'batterySawsImage',
    name: 'Battery Chainsaws',
    alt: 'STIHL Battery Chainsaws',
  },
]

const ChainSawsPage = ({ data }) => {
  const { allHutsonPromotion, ms250Image, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Chainsaws | Hutson Inc</title>
        <meta
          name='description'
          content='STIHL chainsaws are the number one choice for professionals and homeowners. Find the right chainsaw to meet your needs at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Chain Saws',
                'item': 'https://www.hutsoninc.com/stihl/chain-saws/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Chainsaws' />

      <Content>
        <Floater items={floaterItems} />

        <Copy header='STIHL Chainsaws'>
          <p>
            Hutson is your local STIHL chainsaw dealer. We offer a full line of STIHL chainswas
            designed for any purpose. Whether you need to trim a few branches or knock down a
            forest, STIHL has you covered. Looking for a chainsaw to do work around your property?
            Try the <Link to='/stihl/chain-saws/homeowner-saws/'>STIHL Homeowner Chainsaws</Link> or{' '}
            <Link to='/stihl/chain-saws/battery-saws/'>STIHL Battery Chainsaws</Link>. Need
            something bigger for professional work or jobs around the farm? Try out{' '}
            <Link to='/stihl/chain-saws/professional-saws/'>STIHL Professional Chainsaws</Link> or{' '}
            <Link to='/stihl/chain-saws/farm-and-ranch-saws/'>STIHL Farm and Ranch Chainsaws</Link>.
            If you're interested in doing some climbing, check out{' '}
            <Link to='/stihl/chain-saws/in-tree-saws/'>STIHL In-Tree Saws</Link>.
          </p>
        </Copy>

        {promos.length > 0 && <Promos data={promos} type='STIHL Chainsaw' />}
      </Content>

      <FeaturesGrid
        features={[
          {
            image: ms250Image.childImageSharp.gatsbyImageData,
            imageAlt: 'STIHL MS 250 Chainsaw',
            title: 'Choosing the right STIHL chainsaw',
            copy: "Finding the right chainsaw to fit your needs isn't easy, but we've got a few tips to help out. Check out our guide on the meaning behind STIHL chainsaw model numbers to learn more.",
            button: {
              link: '/blog/stihl-chainsaw-model-numbers/',
              text: 'Read the guide on STIHL chainsaw model numbers',
            },
          },
        ]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query chainSawsStihlQuery($category: String = "chain-saws") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    ms250Image: file(relativePath: { eq: "stihl/chain-saws/stihl-ms250-chainsaw.jpg" }) {
      ...SharpImage900
    }
    heroImage: file(relativePath: { eq: "stihl/chain-saws/chain-saws-bg.jpg" }) {
      ...FullWidthImage
    }
    homeownerSawsImage: file(relativePath: { eq: "stihl/chain-saws/homeowner-saws.jpg" }) {
      ...FloatingGridImage
    }
    farmAndRanchSawsImage: file(relativePath: { eq: "stihl/chain-saws/farm-and-ranch-saws.jpg" }) {
      ...FloatingGridImage
    }
    inTreeSawsImage: file(relativePath: { eq: "stihl/chain-saws/in-tree-saws.jpg" }) {
      ...FloatingGridImage
    }
    professionalSawsImage: file(relativePath: { eq: "stihl/chain-saws/professional-saws.jpg" }) {
      ...FloatingGridImage
    }
    batterySawsImage: file(relativePath: { eq: "stihl/chain-saws/battery-saws.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default ChainSawsPage
